/**
 * PHP Email Form Validation - v3.1
 * URL: https://bootstrapmade.com/php-email-form/
 * Author: BootstrapMade.com
 */
(function () {
  "use strict";

  let forms = document.querySelectorAll(".php-email-form");
  let element = document.getElementById("phpError");

  forms.forEach(function (e) {
    e.addEventListener("submit", function (event) {
      event.preventDefault();
      let thisForm = this;
      let action = thisForm.getAttribute("action");
      let recaptcha = thisForm.getAttribute("data-recaptcha-site-key");

      if (!action) {
        displayError(thisForm, "The form action property is not set!");
        return;
      }
      thisForm.querySelector(".btn-loading").classList.add("d-block");
      thisForm.querySelector(".btn-error").classList.remove("d-block");
      thisForm.querySelector(".btn-success").classList.remove("d-block");

      let formData = new FormData(thisForm);

      // if (recaptcha) {
      //   if (typeof grecaptcha !== "undefined") {
      //     grecaptcha.ready(function () {
      //       try {
      //         grecaptcha
      //           .execute(recaptcha, {
      //             action: "php_email_form_submit",
      //           })
      //           .then((token) => {
      //             formData.set("recaptcha-response", token);
      //             php_email_form_submit(thisForm, action, formData);
      //           });
      //       } catch (error) {
      //         displayError(thisForm, error);
      //       }
      //     });
      //   } else {
      //     displayError(
      //       thisForm,
      //       "The reCaptcha javascript API url is not loaded!"
      //     );
      //   }
      // } else {
      //   php_email_form_submit(thisForm, action, formData);
      // }
      php_email_form_submit(thisForm, action, formData);
    });
  });

  function php_email_form_submit(thisForm, action, formData) {
    fetch(action, {
      method: "POST",
      body: formData,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(
            `${response.status} ${response.statusText} ${response.url}`
          );
        }
      })
      .then((data) => {
        thisForm.querySelector(".btn-loading").classList.remove("d-block");
        if (data.trim() == "OK") {
          thisForm.querySelector(".btn-success").classList.add("d-block");
          thisForm.reset();
        } else {
          throw new Error(
            data
              ? data
              : "Form submission failed and no error message returned from: " +
                action
          );
        }
      })
      .catch((error) => {
        displayError(thisForm, error);
      });
  }

  function displayError(thisForm, error) {
    thisForm.querySelector(".btn-error").classList.add("d-block");
    element.classList.add("shake");
    thisForm.querySelector(".btn-loading").classList.remove("d-block");
    thisForm.querySelector(".btn-submit").classList.add("invisible");
    thisForm.querySelector(".error-message").innerHTML = error;
    thisForm.querySelector(".error-message").classList.add("d-block");

    const sleep = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));

    const deError = async () => {
      await sleep(5000);
      thisForm.querySelector(".btn-submit").classList.remove("invisible");
      thisForm.querySelector(".btn-error").classList.remove("d-block");
      thisForm.querySelector(".error-message").classList.remove("d-block");
      element.classList.remove("shake");
      // trigger a DOM reflow
      void element.offsetWidth;
      console.log("void");
    };
    deError();
  }
})();
