function businessHoursDisplay() {
  var today = new Date(),
    stringToday = today.toString(),
    dd = String(today.getDate()).padStart(2, "0"),
    mm = String(today.getMonth() + 1).padStart(2, "0"), //January is 0!
    yyyy = today.getFullYear(),
    wdNum = today.getDay(),
    isClosed = false;
  var morningHours = new Array(7);
  //CHANGE MORNING HOURS HERE - MANUAL
  morningHours[0] = "-";
  morningHours[1] = "08<sup>00</sup> - 12<sup>30</sup>";
  morningHours[2] = "08<sup>00</sup> - 12<sup>30</sup>";
  morningHours[3] = "08<sup>00</sup> - 12<sup>30</sup>";
  morningHours[4] = "08<sup>00</sup> - 12<sup>30</sup>";
  morningHours[5] = "-";
  morningHours[6] = "-";
  var afternoonHours = new Array(7);
  //CHANGE AFTERNOON HOURS HERE - MANUAL
  afternoonHours[0] = "-";
  afternoonHours[1] = "14<sup>30</sup> - 18<sup>30</sup>";
  afternoonHours[2] = "14<sup>30</sup> - 19<sup>00</sup>";
  afternoonHours[3] = "-";
  afternoonHours[4] = "14<sup>30</sup> - 19<sup>00</sup>";
  afternoonHours[5] = "14<sup>30</sup> - 17<sup>00</sup>";
  afternoonHours[6] = "-";
  var mh = morningHours[today.getDay()],
    ah = afternoonHours[today.getDay()];
  var elementLanguage = document.getElementById("hours").getAttribute("data-language");
  //closes practice on weekends
  if (wdNum == "0" || wdNum == "6") {
    var isClosed = true;
  }
  //displays weekday and date of current day
  //english text version
  if (elementLanguage == "en") {
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    var wd = weekday[today.getDay()];
    today = wd + ", " + dd + "." + mm + "." + yyyy;
    document.getElementById("day_and_date").innerHTML = today;
    if (isClosed === true || document.querySelector("html").dataset.closed === true) {
      document.getElementById("day_and_date").innerHTML = today + " (Closed)";
    }
  }
  //german text version
  else {
    var weekday = new Array(7);
    weekday[0] = "Sonntag";
    weekday[1] = "Montag";
    weekday[2] = "Dienstag";
    weekday[3] = "Mittwoch";
    weekday[4] = "Donnerstag";
    weekday[5] = "Freitag";
    weekday[6] = "Samstag";
    var wd = weekday[today.getDay()];
    today = wd + ", der " + dd + "." + mm + "." + yyyy;
    document.getElementById("day_and_date").innerHTML = today;
    if (isClosed === true || document.querySelector("html").dataset.closed === true) {
      document.getElementById("day_and_date").innerHTML = today + " (Geschlossen)";
    }
  }
  //displays working hours of current day
  document.getElementById("morning_hours").innerHTML = mh;
  document.getElementById("afternoon_hours").innerHTML = ah;
  if (isClosed === true || document.querySelector("html").dataset.closed === true) {
    document.getElementById("morning_hours").innerHTML = "-";
    document.getElementById("afternoon_hours").innerHTML = "-";
  }
}
//call today widget only on pages containing it
var hoursElement = document.getElementById("hours");
if (typeof hoursElement != "undefined" && hoursElement != null) {
  businessHoursDisplay();
}
