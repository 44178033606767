import moment from "moment";
import localization from "moment/locale/de";

import cal2024 from "../resources/cals/2024.json";

var isTomorrow = false;
var skipNext = 0;
var currentCal;
var vacationNodes = [];
var displayNodes = [];
var nextDays = 50;

moment().locale("de", localization);
var nowDate = moment().format("L");
var tomDate = moment().add(1, "days").format("L");

if (moment().format("YYYY") == 2024) {
  currentCal = cal2024;
}
function getVDates() {
  // get Nodes from json
  Object.keys(currentCal).forEach(function (key) {
    if (currentCal[key] == false) {
      delete currentCal[key];
    }
  });
  // console.log(Object.keys(currentCal));
}

function consolidateDates(index) {
  var i = 0;
  var startDate = Object.keys(currentCal)[index];
  var d = moment(Object.keys(currentCal)[index], "DD.MM.YYYY").toDate();
  var t = moment(Object.keys(currentCal)[index + i], "DD.MM.YYYY").toDate();
  do {
    d = moment(Object.keys(currentCal)[index + i], "DD.MM.YYYY").toDate();
    i++;
    t = moment(Object.keys(currentCal)[index + i], "DD.MM.YYYY").toDate();
    checkNextDay(d, t);
  } while (isTomorrow === true);
  var endDate = Object.keys(currentCal)[index + i - 1];
  var n;
  // check if Date is single or group
  if (i > 2) {
    // group Node
    n = startDate + " – " + endDate;
    skipNext = i;
  } else {
    // single Node
    n = startDate;
    skipNext = 1;
  }
  // push Node to array
  vacationNodes.push(n);
  // check if Node is within next X days
  if (checkUpcoming(startDate)) {
    //push Node to array
    displayNodes.push(n);
  }
  // check if Array is empty
  if (displayNodes.length === 0) {
    // push Node to array (to prevent empty Array)
    displayNodes.push(n);
  }
}

function checkNextDay(date, nextDate) {
  var d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
  if (d1.getFullYear() == nextDate.getFullYear() && d1.getMonth() == nextDate.getMonth() && d1.getDate() == nextDate.getDate()) {
    isTomorrow = true;
  } else {
    isTomorrow = false;
  }
}

function consolidateAllDates() {
  var length = Object.keys(currentCal).length - 1;
  for (let i = 0; i < length; i = i + skipNext) {
    consolidateDates(i);
  }
}

function checkUpcoming(d) {
  var dateToCheck = moment(d, "DD.MM.YYYY").toDate();
  return moment().diff(dateToCheck, "days") > -nextDays;
}

function buildDisplayList() {
  // Make the list
  let ul = document.createElement("ul");
  ul.style.paddingLeft = 0;
  ul.style.listStyle = "none";
  // Make the list item
  let li = document.createElement("li");
  // Append List
  document.querySelector("#vacation_hours").appendChild(ul);
  // Add Items
  displayNodes.forEach((item) => {
    // Add the item text
    li.innerHTML += item;
    // Add li to the ul
    ul.appendChild(li);
    // Reset the list item
    li = document.createElement("li");
  });
}

function checkIfClosed() {
  if (Object.keys(currentCal).includes(nowDate)) {
    document.querySelector("html").dataset.closed = true;
  }
}

//call today widget only on pages containing it
var hoursElement = document.getElementById("hours");
if (typeof hoursElement != "undefined" && hoursElement != null) {
  getVDates();
  consolidateAllDates();
  buildDisplayList();
  checkIfClosed();
  // console.log("Vacation Nodes: " + vacationNodes);
  // console.log("Display Nodes: " + displayNodes);
}
