import { passiveSupport } from "passive-events-support/src/utils";
passiveSupport({
  events: ["touchstart", "touchmove", "touchenter", "touchend", "touchleave", "wheel", "mousewheel"],
});
// import AOS
import AOS from "aos";
// import bootstrap & dependencies
import bootstrap from "bootstrap";
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// theme js
const script1 = require("./medilab");
// custom module
const script2 = require("./business-hours");
// form validator
const script3 = require("./form-validation");
// cookie banner
const script4 = require("./cookie-banner");
// vacation days
const script5 = require("./vacation-days");
/**
 * Animation on scroll
 */
function aosAnimations() {
  window.addEventListener("load", () => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  });

  AOS.init();
}
/**
 * Testimonials slider  * Rooms slider
 */
function swiperElements() {
  var swiper = new Swiper(".testimonials-slider", {
    loop: false,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
      loop: false,
    },
    mousewheel: {
      invert: false,
      releaseOnEdges: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },

      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });
  var swiper = new Swiper(".rooms-slider", {
    spaceBetween: 30,
    effect: "fade",
    loop: false,
    mousewheel: {
      invert: false,
      releaseOnEdges: true,
    },
    // autoHeight: true,
    pagination: {
      el: ".rooms-slider__pagination",
      clickable: true,
    },
  });
}
// call swiper elements only on pages containing them
var swiperElement = document.getElementById("rooms");
if (typeof swiperElement != "undefined" && swiperElement != null) {
  swiperElements();
}
// call aos animation
aosAnimations();
