/**
 * Template Name: Medilab - v4.2.0
 * Template URL: https://bootstrapmade.com/medilab-free-medical-bootstrap-theme/
 * Author: BootstrapMade.com
 * License: https://bootstrapmade.com/license/
 */
(function () {
  ("use strict");
  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };
  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all);
    if (selectEl) {
      if (all) {
        selectEl.forEach((e) => e.addEventListener(type, listener));
      } else {
        selectEl.addEventListener(type, listener);
      }
    }
  };
  /**
   * Easy on scroll event listener
   */
  const onscroll = (el, listener) => {
    el.addEventListener("scroll", listener);
  };
  /**
   * Navbar links active state on scroll
   */
  let navbarlinks = select("#navbar .scrollto", true);
  const navbarlinksActive = () => {
    let position = window.scrollY + 200;
    navbarlinks.forEach((navbarlink) => {
      if (!navbarlink.hash) return;
      let section = select(navbarlink.hash);
      if (!section) return;
      if (position >= section.offsetTop && position <= section.offsetTop + section.offsetHeight) {
        navbarlink.classList.add("active");
      } else {
        navbarlink.classList.remove("active");
      }
    });
  };
  window.addEventListener("load", navbarlinksActive);
  onscroll(document, navbarlinksActive);
  /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let header = select("#header");
    let offset = header.offsetHeight;

    let elementPos = select(el).offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: "smooth",
    });
  };
  /**
   * Toggle .header-scrolled class to #header when page is scrolled
   */
  let selectHeader = select("#header");
  let selectTopbar = select("#topbar");
  if (selectHeader) {
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add("header-scrolled");
        if (selectTopbar) {
          selectTopbar.classList.add("topbar-scrolled");
        }
      } else {
        selectHeader.classList.remove("header-scrolled");
        if (selectTopbar) {
          selectTopbar.classList.remove("topbar-scrolled");
        }
      }
    };
    window.addEventListener("load", headerScrolled);
    onscroll(document, headerScrolled);
  }
  /**
   * Back to top button
   */
  let backtotop = select(".back-to-top");
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add("active");
      } else {
        backtotop.classList.remove("active");
      }
    };
    window.addEventListener("load", toggleBacktotop);
    onscroll(document, toggleBacktotop);
  }
  /**
   * Mobile nav toggle
   */
  on("click", ".mobile-nav-toggle", function (e) {
    select("#navbar").classList.toggle("navbar-mobile");
    this.classList.toggle("bi-menu");
    this.classList.toggle("btn-close");
    console.log("toggle!");
  });
  /**
   * Mobile nav dropdowns activate
   */
  on(
    "click",
    ".navbar .dropdown > a",
    function (e) {
      if (select("#navbar").classList.contains("navbar-mobile")) {
        e.preventDefault();
        this.nextElementSibling.classList.toggle("dropdown-active");
      }
    },
    true
  );
  /**
   * Scrool with offset on links with a class name .scrollto
   */
  on(
    "click",
    ".scrollto",
    function (e) {
      if (select(this.hash)) {
        e.preventDefault();

        let navbar = select("#navbar");
        if (navbar.classList.contains("navbar-mobile")) {
          navbar.classList.remove("navbar-mobile");
          let navbarToggle = select(".mobile-nav-toggle");
          navbarToggle.classList.toggle("bi-menu");
          navbarToggle.classList.toggle("btn-close");
        }
        scrollto(this.hash);
      }
    },
    true
  );
  /**
   * Scroll with offset on page load with hash links in the url
   */
  window.addEventListener("load", () => {
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash);
      }
    }
  });
  /**
   * Bottom Scroll Listener
   */
  window.onscroll = function (ev) {
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
      backtotop.classList.add("omega");
    } else {
      backtotop.classList.remove("omega");
    }
  };
  /**
   * Preloader
   */
  let preloader = select("#preloader");
  if (preloader) {
    window.addEventListener("load", () => {
      preloader.remove();
    });
  }
  /**
   * Initialize Tooltip
   */
  //    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  //    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  //      return new bootstrap.Tooltip(tooltipTriggerEl)
  //    })
  //
  /**
   * Hide Newsflash
   */
  on("click", "#hideNewsflash", function (e) {
    newsflash.classList.add("closed");
  });
  /*
   *More / Less
   */
  var coll = document.getElementsByClassName("flippable");
  var i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function () {
      var cardBody = this.parentElement.querySelector("div.card-body");
      var cardDetail = this.parentElement.querySelector("div.card-detail");
      const cardDetailHeight = cardDetail.clientHeight;
      console.log(cardDetailHeight);

      if (this.classList.contains("less")) {
        this.classList.remove("less");
        this.classList.add("more");
        cardBody.style.height = cardDetailHeight + "px";
      } else {
        this.classList.remove("more");
        this.classList.add("less");
        cardBody.style.height = 100 + "%";
      }
    });
  }
  /**
   * Language switch
   */
  on(
    "click",
    ".lang-switch",
    function (e) {
      var protocol = "http://", //change to https on production server
        hostname = window.location.hostname,
        languagePath = this.getAttribute("data-language"),
        pageName = this.getAttribute("data-page");
      console.log(hostname);
      if (hostname === "localhost") {
        console.log("local");
        var targetLocation = protocol.concat(hostname, ":1234", languagePath, pageName);
      } else if (hostname === "staging.wendt.sdtrnk.media") {
        console.log("staging");
        protocol = "https://";
        var targetLocation = protocol.concat(hostname, languagePath, pageName);
      } else if (hostname === "ewendt.info") {
        console.log("ewendt.info");
        protocol = "https://";
        var targetLocation = protocol.concat(hostname, languagePath, pageName);
      } else if (hostname === "frauenarztwendt.de") {
        console.log("frauenarztwendt.de");
        protocol = "https://";
        var targetLocation = protocol.concat(hostname, languagePath, pageName);
      } else if (hostname === "www.frauenarztwendt.de") {
        console.log("www.frauenarztwendt.de");
        protocol = "https://";
        var targetLocation = protocol.concat(hostname, languagePath, pageName);
      }
      location.href = targetLocation;
    },
    true
  );
  /**
   * Pseudo Fix Background of Main Section
   */
  let mainBackground = select("#main-bg");
  if (mainBackground) {
    const fixBackground = () => {
      var mainBg = document.querySelector("#main-bg"),
        heroHeight = document.querySelector("#hero").offsetHeight,
        scrollDistance = document.documentElement.scrollTop;

      if (scrollDistance < heroHeight) {
        mainBg.style.top = heroHeight + "px";
        mainBg.classList.remove("fixed");
      } else {
        //      mainBg.style.top = scrollDistance + "px";
        mainBg.classList.add("fixed");
      }
    };
    window.addEventListener("load", fixBackground);
    onscroll(document, fixBackground);
  }
})();
