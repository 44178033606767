{
  "01.01.2024": false,
  "02.01.2024": false,
  "03.01.2024": false,
  "04.01.2024": false,
  "05.01.2024": false,
  "06.01.2024": false,
  "07.01.2024": false,
  "08.01.2024": false,
  "09.01.2024": false,
  "10.01.2024": false,
  "11.01.2024": false,
  "12.01.2024": false,
  "13.01.2024": false,
  "14.01.2024": false,
  "15.01.2024": false,
  "16.01.2024": false,
  "17.01.2024": false,
  "18.01.2024": false,
  "19.01.2024": false,
  "20.01.2024": false,
  "21.01.2024": false,
  "22.01.2024": true,
  "23.01.2024": true,
  "24.01.2024": true,
  "25.01.2024": true,
  "26.01.2024": true,
  "27.01.2024": true,
  "28.01.2024": true,
  "29.01.2024": false,
  "30.01.2024": false,
  "31.01.2024": false,

  "01.02.2024": false,
  "02.02.2024": false,
  "03.02.2024": false,
  "04.02.2024": false,
  "05.02.2024": false,
  "06.02.2024": false,
  "07.02.2024": false,
  "08.02.2024": false,
  "09.02.2024": false,
  "10.02.2024": false,
  "11.02.2024": false,
  "12.02.2024": false,
  "13.02.2024": false,
  "14.02.2024": false,
  "15.02.2024": false,
  "16.02.2024": false,
  "17.02.2024": false,
  "18.02.2024": false,
  "19.02.2024": false,
  "20.02.2024": false,
  "21.02.2024": false,
  "22.02.2024": false,
  "23.02.2024": false,
  "24.02.2024": false,
  "25.02.2024": false,
  "26.02.2024": false,
  "27.02.2024": false,
  "28.02.2024": false,
  "29.02.2024": false,

  "01.03.2024": false,
  "02.03.2024": false,
  "03.03.2024": false,
  "04.03.2024": true,
  "05.03.2024": true,
  "06.03.2024": true,
  "07.03.2024": true,
  "08.03.2024": true,
  "09.03.2024": true,
  "10.03.2024": true,
  "11.03.2024": false,
  "12.03.2024": false,
  "13.03.2024": false,
  "14.03.2024": false,
  "15.03.2024": false,
  "16.03.2024": false,
  "17.03.2024": false,
  "18.03.2024": false,
  "19.03.2024": false,
  "20.03.2024": false,
  "21.03.2024": false,
  "22.03.2024": false,
  "23.03.2024": false,
  "24.03.2024": false,
  "25.03.2024": false,
  "26.03.2024": false,
  "27.03.2024": false,
  "28.03.2024": false,
  "29.03.2024": false,
  "30.03.2024": false,
  "31.03.2024": false,

  "01.04.2024": false,
  "02.04.2024": false,
  "03.04.2024": false,
  "04.04.2024": false,
  "05.04.2024": false,
  "06.04.2024": false,
  "07.04.2024": false,
  "08.04.2024": false,
  "09.04.2024": false,
  "10.04.2024": false,
  "11.04.2024": false,
  "12.04.2024": false,
  "13.04.2024": false,
  "14.04.2024": false,
  "15.04.2024": false,
  "16.04.2024": false,
  "17.04.2024": false,
  "18.04.2024": false,
  "19.04.2024": false,
  "20.04.2024": false,
  "21.04.2024": false,
  "22.04.2024": false,
  "23.04.2024": false,
  "24.04.2024": false,
  "25.04.2024": false,
  "26.04.2024": false,
  "27.04.2024": false,
  "28.04.2024": false,
  "29.04.2024": false,
  "30.04.2024": false,

  "01.05.2024": false,
  "02.05.2024": false,
  "03.05.2024": false,
  "04.05.2024": false,
  "05.05.2024": false,
  "06.05.2024": false,
  "07.05.2024": false,
  "08.05.2024": false,
  "09.05.2024": true,
  "10.05.2024": true,
  "11.05.2024": true,
  "12.05.2024": true,
  "13.05.2024": true,
  "14.05.2024": true,
  "15.05.2024": true,
  "16.05.2024": true,
  "17.05.2024": true,
  "18.05.2024": true,
  "19.05.2024": true,
  "20.05.2024": true,
  "21.05.2024": true,
  "22.05.2024": true,
  "23.05.2024": true,
  "24.05.2024": true,
  "25.05.2024": true,
  "26.05.2024": true,
  "27.05.2024": false,
  "28.05.2024": false,
  "29.05.2024": false,
  "30.05.2024": false,
  "31.05.2024": true,

  "01.06.2024": false,
  "02.06.2024": false,
  "03.06.2024": false,
  "04.06.2024": false,
  "05.06.2024": false,
  "06.06.2024": false,
  "07.06.2024": false,
  "08.06.2024": false,
  "09.06.2024": false,
  "10.06.2024": false,
  "11.06.2024": false,
  "12.06.2024": false,
  "13.06.2024": false,
  "14.06.2024": false,
  "15.06.2024": false,
  "16.06.2024": false,
  "17.06.2024": false,
  "18.06.2024": false,
  "19.06.2024": false,
  "20.06.2024": false,
  "21.06.2024": false,
  "22.06.2024": false,
  "23.06.2024": false,
  "24.06.2024": false,
  "25.06.2024": false,
  "26.06.2024": false,
  "27.06.2024": false,
  "28.06.2024": false,
  "29.06.2024": false,
  "30.06.2024": false,

  "01.07.2024": false,
  "02.07.2024": false,
  "03.07.2024": false,
  "04.07.2024": false,
  "05.07.2024": false,
  "06.07.2024": false,
  "07.07.2024": false,
  "08.07.2024": false,
  "09.07.2024": false,
  "10.07.2024": false,
  "11.07.2024": false,
  "12.07.2024": false,
  "13.07.2024": false,
  "14.07.2024": false,
  "15.07.2024": false,
  "16.07.2024": false,
  "17.07.2024": false,
  "18.07.2024": false,
  "19.07.2024": false,
  "20.07.2024": false,
  "21.07.2024": false,
  "22.07.2024": false,
  "23.07.2024": false,
  "24.07.2024": false,
  "25.07.2024": false,
  "26.07.2024": false,
  "27.07.2024": false,
  "28.07.2024": false,
  "29.07.2024": true,
  "30.07.2024": true,
  "31.07.2024": true,

  "01.08.2024": true,
  "02.08.2024": true,
  "03.08.2024": true,
  "04.08.2024": true,
  "05.08.2024": false,
  "06.08.2024": false,
  "07.08.2024": false,
  "08.08.2024": false,
  "09.08.2024": false,
  "10.08.2024": false,
  "11.08.2024": false,
  "12.08.2024": false,
  "13.08.2024": false,
  "14.08.2024": false,
  "15.08.2024": false,
  "16.08.2024": false,
  "17.08.2024": false,
  "18.08.2024": false,
  "19.08.2024": false,
  "20.08.2024": false,
  "21.08.2024": false,
  "22.08.2024": false,
  "23.08.2024": false,
  "24.08.2024": false,
  "25.08.2024": false,
  "26.08.2024": true,
  "27.08.2024": true,
  "28.08.2024": true,
  "29.08.2024": true,
  "30.08.2024": true,
  "31.08.2024": true,

  "01.09.2024": true,
  "02.09.2024": true,
  "03.09.2024": true,
  "04.09.2024": true,
  "05.09.2024": true,
  "06.09.2024": true,
  "07.09.2024": true,
  "08.09.2024": true,
  "09.09.2024": false,
  "10.09.2024": false,
  "11.09.2024": false,
  "12.09.2024": false,
  "13.09.2024": false,
  "14.09.2024": false,
  "15.09.2024": false,
  "16.09.2024": false,
  "17.09.2024": false,
  "18.09.2024": false,
  "19.09.2024": false,
  "20.09.2024": false,
  "21.09.2024": false,
  "22.09.2024": false,
  "23.09.2024": false,
  "24.09.2024": false,
  "25.09.2024": false,
  "26.09.2024": false,
  "27.09.2024": false,
  "28.09.2024": false,
  "29.09.2024": false,
  "30.09.2024": false,

  "01.10.2024": false,
  "02.10.2024": false,
  "03.10.2024": false,
  "04.10.2024": false,
  "05.10.2024": false,
  "06.10.2024": false,
  "07.10.2024": false,
  "08.10.2024": false,
  "09.10.2024": false,
  "10.10.2024": false,
  "11.10.2024": false,
  "12.10.2024": false,
  "13.10.2024": false,
  "14.10.2024": false,
  "15.10.2024": false,
  "16.10.2024": false,
  "17.10.2024": false,
  "18.10.2024": false,
  "19.10.2024": false,
  "20.10.2024": false,
  "21.10.2024": true,
  "22.10.2024": true,
  "23.10.2024": true,
  "24.10.2024": true,
  "25.10.2024": true,
  "26.10.2024": true,
  "27.10.2024": true,
  "28.10.2024": false,
  "29.10.2024": false,
  "30.10.2024": false,
  "31.10.2024": false,

  "01.11.2024": false,
  "02.11.2024": false,
  "03.11.2024": false,
  "04.11.2024": false,
  "05.11.2024": false,
  "06.11.2024": false,
  "07.11.2024": false,
  "08.11.2024": false,
  "09.11.2024": false,
  "10.11.2024": false,
  "11.11.2024": false,
  "12.11.2024": false,
  "13.11.2024": false,
  "14.11.2024": false,
  "15.11.2024": false,
  "16.11.2024": false,
  "17.11.2024": false,
  "18.11.2024": false,
  "19.11.2024": false,
  "20.11.2024": false,
  "21.11.2024": false,
  "22.11.2024": false,
  "23.11.2024": false,
  "24.11.2024": false,
  "25.11.2024": false,
  "26.11.2024": false,
  "27.11.2024": false,
  "28.11.2024": false,
  "29.11.2024": false,
  "30.11.2024": false,

  "01.12.2024": false,
  "02.12.2024": false,
  "03.12.2024": false,
  "04.12.2024": false,
  "05.12.2024": false,
  "06.12.2024": false,
  "07.12.2024": false,
  "08.12.2024": false,
  "09.12.2024": false,
  "10.12.2024": false,
  "11.12.2024": false,
  "12.12.2024": false,
  "13.12.2024": false,
  "14.12.2024": false,
  "15.12.2024": false,
  "16.12.2024": false,
  "17.12.2024": false,
  "18.12.2024": false,
  "19.12.2024": false,
  "20.12.2024": false,
  "21.12.2024": false,
  "22.12.2024": false,
  "23.12.2024": false,
  "24.12.2024": false,
  "25.12.2024": true,
  "26.12.2024": true,
  "27.12.2024": true,
  "28.12.2024": true,
  "29.12.2024": true,
  "30.12.2024": true,
  "31.12.2024": true
}
